import * as React from "react";

const WhativedoneComponent = () => {
	return (
		<div className="whativedone">
			<h1>what i've done</h1>
		</div>
	)
}

export default WhativedoneComponent;
import cowangie from './cowangie.jpg';
import greek from './greek.png';
import shangkini from './shangkini.jpg';
import starbucks from './starbucks.jpg';
import zither from './zither.png';

export const doodles = [
	{ name: 'cow', src: cowangie},
	{name: 'greek', src: greek},
	{name: 'kini', src: shangkini},
	{name: 'bucks', src: starbucks},
	{name: 'zither', src: zither}
];
export const HAT_TIP =
    'Practical problem solving without sacrificing an intuitive sense of style and cohesion wither it is shade from the sun or complex UI systems.';

export const STARE_TIP = 'Not bothered.';

export const FLOWER_TIP =
    'Sensitive. Aware. Utilizies the inspiration of nature for finding creative solutions rather than reinventing what has already been provided.';

export const BEAD_TIP = 'Mindful and well traveled. Able to find peace wiith clear eyes and heart in any situation.';

export const GLASSES_TIP = 'Always prepared at multiple levels and with a fallback to tackle unforseen issues.';

export const JEANS_TIP = 'Casual. Durable. Able to withstand use and gets better with age, understanding, and wear.';

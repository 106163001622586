import * as React from "react";

const WhatiknowComponent = () => {
	return (
		<div >
			<h1>what i know</h1>
		</div>
	)
}

export default WhatiknowComponent;
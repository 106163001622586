import styled from 'styled-components';

export const PageStyle = styled.main`
    display: flex;
    width: 100%;
`;

export const imgStyle = {
    width: '100%',
};
